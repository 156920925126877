<template>
  <div>
    <div class="flex-between-end mb-20">
      <div class="heading-4">추천상품</div>
    </div>
    <div class="item-container">
      <VueSlickCarousel v-if="list" v-bind="settings">
        <div v-for="item in list" :key="`recommend-${item.code}`" class="default-item recommend-item"
             @mousedown="updateMousePosition($event,'down', item.url)" @mouseup="updateMousePosition($event,'up', item.url)">
          <div class="item-img">
            <div class="thumb-img"><image-alt v-if="item.src" :src="item.src" altType="list"></image-alt></div>
          </div>
          <div class="recommend-con">
            <div class="item-title">{{ item.productName }}</div>
            <div class="item-info">
              <span class="price">{{ Number(item.price).numberFormat() }}원</span>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import carouselMixin from "@/mixins/carouselMixin";
export default {
  mixins: [carouselMixin],
  name: "recommend",
  data() {
    return {
      settings: {
        "slidesToShow": 3,
      },
      list: null,
    }
  },
  created() {
    this.setList();
  },
  methods: {
    async setList() {
      const { result, list } = await this.$api.getRecommend()
      if (result === 'success') this.list = list
    },
    updateMousePosition(event, type, url) {
      // 익스플로러의 버전 체크하기
      let version_IE = "0";
      let ieLower = navigator.userAgent.match( /MSIE [0-9]{1,}/ );
      if ( ieLower != null ){  version_IE = ieLower.toString().replace( "MSIE " , "" );  }

      let x = 0; // 마우스 포인터의 좌측 위치
      let y = 0; // 마우스 포인터의 위쪽 위치

      if ( 0 < version_IE && version_IE < 7 ) { // 인터넷 익스플로러 (ie) 6 이하 버전일 경우 적용될 내용
        x = event.offsetX;
        y = event.offsetY;
      }
      else if ( event.pageX ) { // pageX & pageY를 사용할 수 있는 브라우저일 경우
        x = event.pageX;
        y = event.pageY;
      }
      else { // 그외 브라우저용
        x = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        y = event.clientY + document.body.scrollTop + document.documentElement.scrollTop;
      }

      if (type == 'down') {
        this.mousedownPositionX = x;
        this.mousedownPositionY = y;
      }
      else {
        this.mouseupPositionX = x;
        this.mouseupPositionY = y;

        this.openUrl(url);
      }
    },
    openUrl(url) {
      if (this.mousedownPositionX == this.mouseupPositionX && this.mousedownPositionY == this.mouseupPositionY) {
        window.open(url, '_blank', 'noopener,noreferrer')
      }
    },
  }
}
</script>
